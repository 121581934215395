/* eslint-disable no-console */
import React from "react";
import { useLocation } from "react-router-dom";
import { urlStringToOBject } from "../../App/common/util/util";
import { PackageList } from "../../App/components/organisms";

const PackageListPage: React.FC = () => {
  const location = useLocation();
  const params = urlStringToOBject(location.search);
  return (
    <div>
      <PackageList params={params || {}} partner="PASYDY" />
    </div>
  );
};

export default PackageListPage;
