import React from "react";
import { InputTextAreaField } from "../commonFields/InputTextAreaField";
import { InputCheckBoxField } from "../commonFields";
import { BookingWrapper } from "./Booking.style";

export const BookingCommentInput: React.FC = () => (
  <BookingWrapper>
    <div className="booking-container">
      <div className="booking-title">Σχόλια</div>
      <InputTextAreaField label={""} name={"comment"} />
      <InputCheckBoxField
        label=""
        name="acceptPasydy"
        rules={[
          {
            required: true,
            message: "",
          },
        ]}
        options={[
          {
            label:
              "Επιβεβαιώνω πως τα στοιχεία κράτησης είναι αληθή και ταυτοποιούνται με τα στοιχεία ενεργού μέλους της ΠΑΣΥΔΥ. Σε αντίθετη περίπτωση η Poupas for Holidays διατηρεί το δικαίωμα επιπλέον χρέωσης ή ακόμη κι ακύρωσης της κράτησης σας.",
            value: "YES",
          },
        ]}
      />
      <InputCheckBoxField
        label=""
        name="accept"
        rules={[
          {
            required: true,
            message: "Συμφωνώ με τους Όρους και Προϋποθέσεις",
          },
        ]}
        options={[
          { label: "Συμφωνώ με τους Όρους και Προϋποθέσεις", value: "YES" },
        ]}
      />
    </div>
  </BookingWrapper>
);
