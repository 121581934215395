/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { Empty, TableProps } from "antd";
import { find, keyBy, map, mapValues } from "lodash";
import { UserOutlined } from "@ant-design/icons";
import { Form, Table, Tooltip } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import {
  IExclusiveHotel64774,
  IExclusiveHotelRoomTypeAllotment,
  IHotelBookingPhysicalRoom,
  IRoomTypeAllotmentPriceList,
} from "../../../common/types";
import { fetchExclusiveHotelRoomTypeAvailableStoreFromFirestore } from "../../../firestore/firestoreService/ExclusiveHotelRoomTypeAvailableStore";
import { SelectField } from "../commonFields";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToExclusiveHotel64774FromFirestore } from "../../../firestore/firestoreService";
import { HotelPriceListWrapper } from "./Hotel.style";
import { MealPriceSelection } from "./components/MealPriceSelection";
import { HotelRoomInfo } from ".";
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function createArrayFromNumber(n: number) {
  return Array.from({ length: n }, (_, index) => index);
}
const createGuestIcons = (guestNumber: number) =>
  createArrayFromNumber(guestNumber).map((i: number) => (
    <UserOutlined key={i} />
  ));
interface IProps {
  offerId: string;
  hotelId: string;
  addRoom: (room: IHotelBookingPhysicalRoom) => void;
  removeRoom: (id: string) => void;
  startDate: string;
  adultNumbers: number;
  childrenNumber: number;
  infant: number;
  isMobile: boolean;
  reCall: string;
}
export const HotelPriceList: React.FC<IProps> = ({
  offerId,
  hotelId,
  addRoom,
  removeRoom,
  startDate,
  adultNumbers,
  childrenNumber,
  infant,
  isMobile,
  reCall,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [selectedPeriod, setSelectedPeriod] =
    React.useState<IExclusiveHotel64774>();
  const [roomOffers, setRoomOffers] = React.useState<
    IRoomTypeAllotmentPriceList[]
  >([]);
  userFirestoreDoc({
    query: () =>
      listenToExclusiveHotel64774FromFirestore(hotelId, offerId || ""),
    data: setSelectedPeriod,
    shouldExecute: !!offerId && !!hotelId,
    local: true,
    deps: [dispatch, offerId],
  });
  const handleAddRoomToCart =
    (record: IRoomTypeAllotmentPriceList) => async () => {
      const values = await form.validateFields();
      map(values, (key, value) => {
        if (key !== record.id) {
          form.setFieldValue([key, "rooms"], 0);
        }
      });
      const mealsPrice =
        find(record.mealsPrices, ["name", values[record.id].mealType])?.price ||
        0;
      if (+values[record.id].rooms > 0) {
        addRoom({
          id: record.id,
          roomId: record.roomId,
          adultNumber: record.adults,
          childNumber: record.childs,
          infantNumber: +infant,
          totalPrice:
            ((+record.discountPrice || +record.totalPrice) + +mealsPrice) *
            +values[record.id].rooms,
          sellPrice:
            (+record.totalPrice + +mealsPrice) * +values[record.id].rooms,
          netPrice: (+record.netPrice + +mealsPrice) * +values[record.id].rooms,
          roomNumber: +values[record.id].rooms,
          mealPrice: +mealsPrice,
          roomPrice: +record.discountPrice || +record.totalPrice,
          mealType: values[record.id]?.mealType || record.mealsType || "",
          refundableDate: record.refundableDate || "",
          cancelationPolicy: record.cancelationPolicy,
          mainGuest: null,
          onRequest: selectedPeriod?.onRequest || false,
        });
      } else {
        removeRoom(record.id);
      }
    };

  useFirestoreCollection({
    query: () =>
      fetchExclusiveHotelRoomTypeAvailableStoreFromFirestore(hotelId, offerId, [
        {
          type: "filter",
          name: "name",
          opr: "==",
          value: startDate,
        },
        {
          type: "filter",
          name: "stopSale",
          opr: "==",
          value: false,
        },
      ]),
    data: async (data: IExclusiveHotelRoomTypeAllotment[]) => {
      setLoading(true);
      setRoomOffers([]);
      const roomList: any = [];
      console.log(data);
      data
        .filter((v) => !v.stopSale && +(v.allotment || 0) < +v.roomAllotment)
        .map((record: IExclusiveHotelRoomTypeAllotment) => {
          roomList.push(
            ...record.priceList
              .filter(
                (v: IRoomTypeAllotmentPriceList) =>
                  +v.adults === +adultNumbers && +v.childs === +childrenNumber,
              )
              ?.map((compat: IRoomTypeAllotmentPriceList) => ({
                ...compat,
                roomTypeAllotmentId: record.id,
                roomId: record.roomId,
                roomAvailable: +record.roomAllotment - +(record.allotment || 0),
              })),
          );
        });
      const processedDataSource = roomList.reduce((acc: any, record: any) => {
        const existing = acc.find((item: any) => item.roomId === record.roomId);
        if (existing) {
          existing.rowSpan += 1;
          record.rowSpan = 0; // This will hide the cell
          acc.push(record);
        } else {
          record.rowSpan = 1; // This record starts a new group
          acc.push(record);
        }
        return acc;
      }, []);
      await delay(1000);
      setRoomOffers([...processedDataSource]);
      setLoading(false);
    },
    local: true,
    shouldExecute: !!startDate && !!offerId,
    deps: [dispatch, startDate, offerId, adultNumbers, childrenNumber, reCall],
  });
  const detailsColumns: TableProps<any>["columns"] = [
    {
      title: "Αριθμός Δωματίων",
      width: 150,
      render: (_: string, record) => (
        <>
          <Tooltip
            title={`${record.adults} ${record.adults === 1 ? "Ενήλικας" : "Ενήλικες"} ${record.childs || ""}
          ${(record.childs === 1 && "Παιδί") || ""}  ${(record.childs > 1 && "Παιδιά") || ""}`}
          >
            {createGuestIcons(record.adults)}{" "}
            <span style={{ fontSize: 11 }}>
              {createGuestIcons(record.childs)}
            </span>
          </Tooltip>
          {record.discountPrice ? (
            <div className="hotelPriceList-price">
              <span className="hotelPriceList-price-no-discount">
                €{record.totalPrice}
              </span>
              <span>€ {record.discountPrice}</span>
            </div>
          ) : (
            <div className="hotelPriceList-price">
              <span>€ {record.totalPrice}</span>
            </div>
          )}
          <MealPriceSelection
            offer={record}
            recordId={record.id}
            addRoom={handleAddRoomToCart(record)}
          />
          <SelectField
            label={"Αριθμός Δωματίων"}
            name={[record.id, "rooms"]}
            onChange={handleAddRoomToCart(record)}
            style={{ width: 120 }}
            options={[
              { value: 0, label: "0" },
              { value: 1, label: "1" },
            ]}
          />
        </>
      ),
    },
  ];
  const fixedColumns: TableProps<any>["columns"] = [
    {
      title: "Δωμάτιο",
      width: 300,
      dataIndex: "roomId",
      render: (roomId: string, record) => (
        <div>
          <HotelRoomInfo
            roomId={roomId}
            hotelId={hotelId}
            onRequest={selectedPeriod?.onRequest || false}
            roomAvailable={record.roomAvailable}
          />
          {isMobile && (
            <Table
              columns={detailsColumns}
              rowKey="id"
              dataSource={roomOffers.filter((r) => r.roomId === roomId)}
              pagination={false}
              loading={loading}
            />
          )}
        </div>
      ),
      onCell: (record) => ({
        rowSpan: record.rowSpan,
        colSpan: isMobile && record.rowSpan === 1 ? 4 : 1,
        className: record.rowSpan === 1 ? "hotelPriceList-roomCol" : "",
      }),
      align: "left",
      className: "hotelPriceList-roomCol",
    },
    {
      title: "Αριθμός Επισκεπτών",
      dataIndex: "title",
      responsive: ["sm"],
      width: 150,
      render: (_: string, record) => (
        <Tooltip
          title={`${record.adults} ${record.adults === 1 ? "Ενήλικας" : "Ενήλικες"} ${record.childs || ""}
          ${(record.childs === 1 && "Παιδί") || ""}  ${(record.childs > 1 && "Παιδιά") || ""}  ${+infant === 1 ? "1 βρέφος" : ""}`}
        >
          {createGuestIcons(record.adults)}{" "}
          <span style={{ fontSize: 11 }}>
            {createGuestIcons(record.childs)}
            {+infant === 1 && (
              <img
                src="/assets/baby-boy.png"
                alt=""
                style={{ height: 12, marginLeft: 5 }}
              />
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Τιμή",
      dataIndex: "totalPrice",
      responsive: ["sm"],
      width: 150,
      render: (price: string, record) =>
        record.discountPrice ? (
          <div className="hotelPriceList-price">
            <span className="hotelPriceList-price-no-discount">
              €{Math.ceil(+price + +price * 0.1)}
            </span>
            <span>€ {record.discountPrice}</span>
          </div>
        ) : (
          <div className="hotelPriceList-price">
            <span>€ {price}</span>
          </div>
        ),
    },
    {
      title: "Πολιτική Δωματίου",
      dataIndex: "mealsTypePrices",
      responsive: ["sm"],
      render: (_: string, record) => (
        <MealPriceSelection
          offer={record}
          recordId={record.id}
          addRoom={handleAddRoomToCart(record)}
        />
      ),
    },
    {
      title: "Αριθμός Δωματίων",
      width: 150,
      responsive: ["sm"],
      render: (_: string, record) => (
        <SelectField
          label={""}
          name={[record.id, "rooms"]}
          onChange={handleAddRoomToCart(record)}
          style={{ width: 120 }}
          options={[
            { value: 0, label: "0" },
            { value: 1, label: "1" },
          ]}
        />
      ),
    },
  ];
  React.useEffect(() => form.resetFields, [roomOffers]);
  return (
    <HotelPriceListWrapper>
      {" "}
      <Form
        form={form}
        name="horizontal_login"
        autoComplete="off"
        initialValues={mapValues(
          keyBy(roomOffers, "id"),
          (item: IRoomTypeAllotmentPriceList) => ({
            rooms: 0,
            mealType: item.mealsType,
          }),
        )}
      >
        {!loading && roomOffers.length === 0 && (
          <Empty description={"Ξενοδοχείο Πλήρες"}>
            Δυστυχώς το Ξενοδοχείο είναι πλήρης για τις ημερομηνίες που
            αναζητάτε. Παρακαλώ επιλέξτε νέα ημερομηνία για να προχωρήσετε με
            κράτηση.
          </Empty>
        )}

        <Table
          columns={fixedColumns}
          rowKey="id"
          dataSource={roomOffers}
          pagination={false}
          loading={loading}
        />
      </Form>
    </HotelPriceListWrapper>
  );
};
