import { Skeleton } from "antd";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  HotelBooking,
  HotelDetails,
  HotelHome,
  HotelList,
  PackageHome,
  ThinksToDoHome,
} from "../components/organisms";
import { useFirestoreCollection } from "../hooks/useFirestoreCollection";
import {
  fetchAmenity2092FromFirestore,
  fetchCity83431FromFirestore,
} from "../firestore/firestoreService";
import { listenToAmenity2092 } from "../redux/data/amenity2092/amenity2092Actions";
import PackageListPage from "../../Domain/PackageList/PackageListPage";
import PackageViewPage from "../../Domain/PackageView/PackageView";
import PackageBookingPage from "../../Domain/PackageBooking/PackageBooking";
import HomePage from "../../Domain/HomePage/HomePage";
import { listenToCities } from "../redux/data/city/cityActions";
import HotelBookingConfirmation from "../../Domain/HotelBooking/HotelBookingConfirmation";

interface IClickableProps {
  childProps: string;
}

const AppRouter: React.FC<IClickableProps> = (): JSX.Element => {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchAmenity2092FromFirestore([]),
    data: listenToAmenity2092,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  return (
    <Suspense fallback={<Skeleton paragraph active loading />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/packages-home" element={<PackageHome />} />
        <Route path="/packages" element={<PackageListPage />} />
        <Route path="/package/:packageId" element={<PackageViewPage />} />
        <Route
          path="/package-booking/:bookingId"
          element={<PackageBookingPage />}
        />
        <Route path="/hotels-home" element={<HotelHome />} />
        <Route path="/hotels" element={<HotelList />} />
        <Route path="/hotel/:hotelId" element={<HotelDetails />} />
        <Route path="/hotel-booking/:bookingId" element={<HotelBooking />} />
        <Route
          path="/hotel-booking-confirm/:bookingId"
          element={<HotelBookingConfirmation />}
        />
        <Route
          path="/hotel-booking-on-request-confirm/:bookingId"
          element={<HotelBookingConfirmation onRequest />}
        />
        <Route
          path="/hotel-booking-confirm/"
          element={<HotelBookingConfirmation />}
        />
        <Route path="/things-to-do" element={<ThinksToDoHome />} />
        <Route
          path="*"
          element={
            <main>
              <p>Theres nothing here!</p>
            </main>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
