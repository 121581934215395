/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useDispatch } from "react-redux";
import { List } from "antd";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { orderBy } from "lodash";
import { HotelsFilters } from "../../molecules/Hotel/HotelsFilters";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchHotel28355FromFirestore } from "../../../firestore/firestoreService/Hotel28355Store";
import { IHotel28355 } from "../../../common/types";
import { HotelItem } from "../../molecules";
import { HotelWrapper } from "./Hotel.style";

export const HotelList: React.FC = () => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilters] = React.useState(true);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const [hotels, setHotels] = React.useState<IHotel28355[]>([]);
  useFirestoreCollection({
    query: () =>
      fetchHotel28355FromFirestore([
        {
          type: "filter",
          name: "exclusiveOffer",
          opr: "==",
          value: true,
        },
      ]),
    data: setHotels,
    local: true,
    deps: [dispatch],
  });
  const handelShowFilter = () => {
    setShowFilters((v) => !v);
  };
  return (
    <HotelWrapper>
      <div className="hotel">
        <div className="hotel-body">
          <div
            className={classNames("hotel-left", {
              hide: showFilter,
            })}
          >
            <HotelsFilters
              showFilter={handelShowFilter}
              isTabletOrMobile={isTabletOrMobile}
              params={undefined}
              url={""}
            />
          </div>

          <div className="hotel-main">
            <div className="hotel-title">
              <img
                src="/assets/HotelsHomePage.png"
                style={{ width: "100%" }}
                alt=""
              />
            </div>
            <List
              dataSource={orderBy(hotels, ["name"])}
              renderItem={(item) => (
                <HotelItem
                  hotelId={item.id}
                  hotel={item}
                  isTabletOrMobile={isTabletOrMobile}
                />
              )}
            />
          </div>
        </div>
      </div>
    </HotelWrapper>
  );
};
