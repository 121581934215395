/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import cuid from "cuid";
import { flatMap, some } from "lodash";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Alert } from "../../atoms";
import { IHotelBookingPhysicalRoom } from "../../../common/types";
import { addBooking30512HotelToFirestore } from "../../../firestore/firestoreService";
import { CartHotelTotalBtn } from "../Cart/CartHotelTotalBtn";
import { calculateDiscountedAmount } from "../../../common/util/util";
import { HotelRoomCartItem } from "./components/HotelRoomCartItem";
import { HotelCartWrapper } from "./Hotel.style";

interface IProps {
  rooms: IHotelBookingPhysicalRoom[];
  removeRoom?: (id: string) => void;
  startDate: string;
  hotelId: string;
  exclusiveOfferId: string;
  checkInCheckOut: string;
  totalPrice: number;
  netPrice: number;
  sellPrice: number;
  isTabletOrMobile: boolean;
  onShowCart?: () => void;
}

function createRoomArray(roomData: IHotelBookingPhysicalRoom) {
  const rooms: IHotelBookingPhysicalRoom[] = [];

  for (let i = 0; i < roomData.roomNumber; i++) {
    rooms.push({
      adultNumber: roomData.adultNumber,
      cancelationPolicy: roomData.cancelationPolicy,
      childNumber: roomData.childNumber,
      id: cuid(),
      infantNumber: roomData.infantNumber,
      mealPrice: roomData.mealPrice,
      mealType: roomData.mealType,
      refundableDate: roomData.refundableDate,
      roomId: roomData.roomId,
      roomPrice: roomData.roomPrice,
      totalPrice: +roomData.roomPrice + +roomData.mealPrice,
      sellPrice: +roomData.sellPrice + +roomData.mealPrice,
      netPrice: +roomData.netPrice + +roomData.mealPrice,
      roomNumber: 1,
      mainGuest: null,
      onRequest: roomData.onRequest,
    });
  }

  return rooms;
}
export const HotelCart: React.FC<IProps> = ({
  rooms,
  removeRoom,
  startDate,
  hotelId,
  exclusiveOfferId,
  checkInCheckOut,
  totalPrice,
  netPrice,
  sellPrice,
  isTabletOrMobile,
  onShowCart,
}) => {
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleBookNow = async () => {
    try {
      setLoading(true);
      setError("");
      if (!hotelId) {
        throw new Error("No Hotel");
      }
      if (!rooms || rooms.length === 0) {
        throw new Error("No rooms");
      }
      const physicalRooms = rooms.map((room) => ({
        rooms: createRoomArray(room),
      }));
      const [checkIn, checkOut] = checkInCheckOut.split("-");
      const booking = await addBooking30512HotelToFirestore({
        hotelId,
        rooms: flatMap(physicalRooms, "rooms"),
        exclusiveOfferId,
        checkIn: checkIn || "",
        checkOut: checkOut || "",
        totalPayment: totalPrice,
        netPrice,
        sellPrice,
        partialPayment: calculateDiscountedAmount(+totalPrice, 70),
        remainingAmount: calculateDiscountedAmount(+totalPrice, 30),
        paymentStatus: "PENDING",
        onRequest: some(rooms, { onRequest: true }),
      });
      setLoading(false);
      history(`/hotel-booking/${booking.id}`);
    } catch (err: any) {
      setLoading(false);
      setError(err.message || err);
    }

    // eslint-disable-next-line no-console
  };
  return (
    <HotelCartWrapper>
      <div className="HotelCart-title">
        Πληροφορίες κράτησης{" "}
        {isTabletOrMobile && (
          <div className="cart-close">
            <CloseCircleOutlined onClick={onShowCart} />
          </div>
        )}
      </div>
      <div className="HotelCart-body">
        {rooms.length === 0 && (
          <div className="HotelCart-noRoom">
            Δεν έχει γίνει καμία Επιλογή Δωματίων ακόμη
          </div>
        )}
        {error !== "" && <Alert message={error} type="error" />}
        {rooms.length > 0 &&
          rooms.map((room) => (
            <HotelRoomCartItem
              key={room.id}
              hotelId={hotelId || ""}
              room={room}
              removeRoom={removeRoom}
            />
          ))}
        {rooms.length > 0 && (
          <div className="cart-footer">
            <CartHotelTotalBtn
              submit={removeRoom && handleBookNow}
              loading={loading}
              finalPrice={totalPrice}
              checkInCheckOut={checkInCheckOut}
              onRequest={some(rooms, { onRequest: true })}
            />
          </div>
        )}
      </div>
    </HotelCartWrapper>
  );
};
