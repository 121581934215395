/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Divider, Space } from "../../atoms";
import { Logo } from "../../atoms/Logo/Logo";
import { BurgerMenuBtn } from "../../molecules";
import { HeaderWrapper } from "./Header.style";

interface IHeaderProps {
  isMobile: boolean;
  setShowMenu: (show: boolean) => void;
  showMenu: boolean;
}
export const Header: React.FC<IHeaderProps> = ({
  isMobile,
  setShowMenu,
  showMenu,
}) => {
  const location = useLocation();
  const { selectedPackage } = useSelector((state: any) => state.packages);
  const [isPasydy, setIsPasydy] = React.useState(false);
  const handleShowMobileMenu = (e: any) => {
    setShowMenu(e.target.checked);
  };
  React.useEffect(() => {
    console.log(location);
    if (
      selectedPackage?.partnerId === "PASYDY" ||
      location.pathname.includes("pasydy")
    ) {
      setIsPasydy(true);
    } else {
      setIsPasydy(false);
    }
  }, [location, selectedPackage]);
  return (
    <HeaderWrapper>
      <Logo />

      <div className="header-pasydy-logo-name">
        για
        <img className="header-pasydy-logo" src="/assets/PASYDY.png" alt="" />
      </div>

      {!isMobile && (
        <Space style={{ display: "flex" }} className="header-menu">
          <Link to={"/"}>
            <FormattedMessage
              description="Packages"
              defaultMessage="Packages"
              id="46PJ2T"
            />
          </Link>
          {!isPasydy && (
            <>
              {" "}
              <Divider type="vertical" />
              <Link to="/hotels">Ξενοδοχεία Κύπρου</Link>
              <Divider type="vertical" />
            </>
          )}
        </Space>
      )}
      {!isMobile && (
        <div className="header-phone-number">
          {isPasydy ? (
            <a href="mailto:pasydy@poupas.com.cy">
              <MailOutlined className="header-phone-number-icon" />
            </a>
          ) : (
            <a href="mailto:reservations@poupas.com.cy">
              <MailOutlined className="header-phone-number-icon" />
            </a>
          )}
          <PhoneOutlined className="header-phone-number-icon" />
          <div className="header-phone-number-text">
            <div className="header-phone-number-first">7008 7003</div>
            <div>+35722252508</div>
          </div>
        </div>
      )}
      {/* {!isMobile && (
        <LeftMenu
          authenticated={authenticated}
          login={handleLogin}
          logout={handleLogout}
          currentUser={currentUser}
        />
      )} */}
      {isMobile && (
        <div className="header-mobile-icon">
          <BurgerMenuBtn
            onClick={handleShowMobileMenu}
            checked={showMenu}
          ></BurgerMenuBtn>
        </div>
      )}
    </HeaderWrapper>
  );
};
