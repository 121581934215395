/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import {
  fetchCategory81497FromFirestore,
  fetchCity83431FromFirestore,
} from "../../App/firestore/firestoreService";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { listenToCategories } from "../../App/redux/data/category/categoryActions";
import { PackageSearchBox } from "../../App/components/molecules";
import { listenToCities } from "../../App/redux/data/city/cityActions";
import { HomePageWrapper, MyGalleryWrapper } from "./HotelPage.style";

const HomePage: React.FC = () => {
  const { cities } = useSelector((state: any) => state.cities);
  const { categories } = useSelector((state: any) => state.categories);
  useFirestoreCollection({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [],
  });
  useFirestoreCollection({
    query: () =>
      fetchCategory81497FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCategories,
    deps: [],
  });
  return (
    <HomePageWrapper>
      <div className="scroll-full">
        <div className="standard-home-container">
          <div className="standard-full">
            <div className="">
              <div className=" full-height keep-scroll">
                <div className="bgimg bg-hotel"></div>
                <div className="heading">
                  <div className="flights-search-header">
                    <span className="blue-color">
                      Αναζητήστε το δικό σας Πακέτο!
                    </span>
                  </div>
                  <div>
                    <PackageSearchBox cities={cities} categories={categories} />
                  </div>
                </div>
                <div className="space-footer"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-heading">
            <h2 className="blue-color">Καλοκαίρι 2024</h2>
            <div>
              Αποκλειστικές Προσφορές Μόνο για τα μέλη της ΠΑΣΥΔΥ και την Άμεση
              Οικογένεια Μόνο Η επιχορήγηση 13% εφαρμόζεται ήδη σε όλες τις
              προσφορές που βλέπετε στην ιστοσελίδα μας
            </div>
          </div>
          <MyGalleryWrapper>
            <Row gutter={0}>
              <Col span={15}>
                <Link to="/packages?from=all&to=all&when=07-2024_08-2024&category=01D_01O&price=0_5000">
                  <img
                    src="/assets/images/Greece-Image-Button.png"
                    alt="Greece-Image-Button.png"
                    style={{ width: 500, height: 300 }}
                  />
                </Link>
              </Col>
              <Col span={9}>
                <Link to="/packages?from=all&to=all&when=all&category=02D_05D&price=0_5000">
                  <img
                    src="/assets/images/Dynamic-Image-Button.png"
                    alt="Dynamic-Image-Button.png"
                    style={{ width: 300, height: 300 }}
                  />
                </Link>{" "}
              </Col>
              <Col span={9}>
                <Link to="/packages?from=all&to=all&when=07-2024_08-2024&category=05O&price=0_5000">
                  <img
                    src="/assets/images/Long-Distance-Imgae-Button.png"
                    alt="Long-Distance-Imgae-Button.png"
                    style={{ width: 300, height: 300 }}
                  />
                </Link>{" "}
              </Col>
              <Col span={15}>
                <Link to="/packages?from=all&to=all&when=07-2024_08-2024&category=02O&price=0_5000">
                  <img
                    src="/assets/images/Europe-Image-Button.png"
                    alt="Europe-Image-Button.png"
                    style={{ width: 500, height: 300 }}
                  />
                </Link>{" "}
              </Col>
            </Row>
          </MyGalleryWrapper>
        </div>
        <div className="footer">
          <img src="/assets/gov_logos.jpg" alt="" />
        </div>
      </div>
    </HomePageWrapper>
  );
};

export default HomePage;
